import React from 'react';
import { Box, Typography, Grid, Stack, Card } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StoreIcon from '@mui/icons-material/Store';
import AutorenewIcon from '@mui/icons-material/Autorenew';

interface FeatureItemProps {
  text: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ text }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <CheckIcon sx={{ color: 'primary.main' }} />
    <Typography variant="body1" color="text.secondary">
      {text}
    </Typography>
  </Stack>
);

interface BenefitCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const BenefitCard: React.FC<BenefitCardProps> = ({ icon, title, description }) => (
  <Card
    sx={{
      p: 2,
      display: 'flex',
      alignItems: 'flex-start',
      gap: 2,
      boxShadow: 'none',
      bgcolor: 'background.default',
      mb: 2
    }}
  >
    <Box
      sx={{
        p: 1.5,
        borderRadius: '50%',
        bgcolor: 'primary.50',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {icon}
    </Box>
    <Box>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  </Card>
);

const PlatformCompatibility: React.FC = () => {
  return (
   
      <Grid container spacing={4} >
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
            Compatible with all your favorite platforms
          </Typography>
          
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            Spotrue integrates seamlessly with your existing marketing tools and
            platforms, offering comprehensive support for all your auditing needs.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <FeatureItem text="Excel & PPT Integration" />
                <FeatureItem text="Custom reporting options" />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <FeatureItem text="Tailored audit solutions" />
                <FeatureItem text="Semi - Automated data processing" />
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <BenefitCard
              icon={<PhoneIphoneIcon color="primary" />}
              title="Responsive settings"
              description="Easily adaptable audit configurations for any campaign."
            />
            
            <BenefitCard
              icon={<StoreIcon color="primary" />}
              title="Verified reporting"
              description="Live status updates and verified reporting for ongoing campaigns."
            />
            
            <BenefitCard
              icon={<AutorenewIcon color="primary" />}
              title="Conversion focussed"
              description="Prevent fraud, ensure brand integrity, and maximize your return on investment."
            />
          </Stack>
        </Grid>
      </Grid>
   
  );
};

export default PlatformCompatibility;