import React from "react";
import { Typography, Button, Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";

interface AuditRequestCommentProps {
  gogigReqId?: string;
  reviewComment?: string;
  campaignType?: string;
}

const AuditRequestComment: React.FC<AuditRequestCommentProps> = ({
  gogigReqId,
  reviewComment,
  campaignType,
}) => {
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/upload-createRequest/1/${campaignType}/${gogigReqId}`); // Navigate to the edit page
  };

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#333" }}
      >
        Audit Request Review
      </Typography>
      <Divider />
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          mt: 1,
          flexDirection: "column",
          gap: 1,
          minHeight: "150px",
        }}
      >
        <Box display="flex" sx={{ gap: 1 }}>
          <AccountCircle sx={{ color: "#666" }} />
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight : "bold",
              whiteSpace: "nowrap", // Prevent wrapping
              maxWidth: "200px",
            }}
          >
            Reviewer Comment :
          </Typography>
        </Box>
        {/* Comment Content */}
        <Typography
          variant="body1"
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            color: "#444",
          }}
        >
          {reviewComment || "No client comments available"}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end"}}>
        <Button variant="contained" color="primary" onClick={handleEditClick}>
          Edit Your Campaign
        </Button>
      </Box>
    </>
  );
};

export default AuditRequestComment;
