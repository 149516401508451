import React from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  styled, 
  useTheme 
} from '@mui/material';

// Styled components
const IconWrapper = styled(Box)(({ theme }) => ({
  
  borderRadius: '50%',
  width: '64px',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3)
}));

interface IconProps {
  src: string;
}

const StepIcon: React.FC<IconProps> = ({ src }) => (
  <Box
    component="img"
    src={src}
    alt="Step Icon"
    sx={{
      width: '32px',
      height: '32px',
      filter: 'brightness(0) saturate(100%) invert(39%) sepia(13%) saturate(1794%) hue-rotate(86deg) brightness(94%) contrast(88%)'
    }}
  />
);

interface ProcessStepProps {
  iconSrc: string;
  title: string;
  description: string;
}

const ProcessStep: React.FC<ProcessStepProps> = ({ iconSrc, title, description }) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <IconWrapper>
          <StepIcon src={iconSrc} />
        </IconWrapper>
        <Typography 
          variant="h6" 
          component="h3" 
          sx={{ 
            fontWeight: 600,
            mb: 2,
            color: theme.palette.text.primary // Using primary text color from the theme
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: theme.palette.text.secondary, // Using secondary text color from the theme
            maxWidth: '90%',
            margin: '0 auto',
            lineHeight: 1.7
          }}
        >
          {description}
        </Typography>
      </Box>
    </Grid>
  );
};

const AuditToolProcess: React.FC = () => {
  const theme = useTheme(); // Get the theme

  const steps = [
    {
      iconSrc: 'https://spotrue.com/wp-content/uploads/2024/09/images-1.png',
      title: 'Upload',
      description: 'Sign up and upload an Excel file containing The vehicle registration numbers and a PPT containing images of the branded vehicles.'
    },
    {
      iconSrc: 'https://spotrue.com/wp-content/uploads/2024/09/8649201-1.png',
      title: 'Process',
      description: 'Submit the files after uploading, It will take some 2-3 days to process the data using AI and ML algorithms, along with manual verification.'
    },
    {
      iconSrc: 'https://spotrue.com/wp-content/uploads/2024/09/cropped-h-Copy.png',
      title: 'Verify',
      description: 'Once the Processing is done, the email will be sent to registered email id with url and login credentials, where you can download the report.'
    }
  ];

  return (
    <Box sx={{  backgroundColor: theme.palette.background.default }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography 
            variant="subtitle2" 
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 500,
              letterSpacing: '0.1em',
              mb: 3,
              textTransform: 'uppercase'
            }}
          >
            EVERYTHING YOU NEED
          </Typography>
          <Typography 
            variant="h3" 
            component="h2" 
            sx={{ 
              fontWeight: 700,
              color: theme.palette.text.primary, // Primary text color
              fontSize: { xs: '2rem', md: '2.5rem' },
              lineHeight: 1.2,
              mb: 1
            }}
          >
            The most powerful audit tool
          </Typography>
          <Typography 
            variant="h3" 
            component="h2" 
            sx={{ 
              fontWeight: 700,
              color: theme.palette.text.primary, // Primary text color
              fontSize: { xs: '2rem', md: '2.5rem' },
              lineHeight: 1.2
            }}
          >
            for your business
          </Typography>
        </Box>

        {/* Process Steps */}
        <Grid container spacing={6}>
          {steps.map((step, index) => (
            <ProcessStep
              key={index}
              iconSrc={step.iconSrc}
              title={step.title}
              description={step.description}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default AuditToolProcess;
