import { useState } from "react";
import { Severity } from "./Snackbar";

interface SnackbarState {
  open: boolean;
  message: string;
  severity: Severity;
}

export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const openSnackbar = (message: string, severity: Severity = "info") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return {
    snackbar,
    openSnackbar,
    closeSnackbar,
  };
};
