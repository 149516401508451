import React from "react";
import { 
  Box, 
  Container,
  Skeleton,
  Typography
} from "@mui/material";
import Dashboard from "./Dashboard";
import { useAuthContext } from "../../providers/AuthProvider";
import CampaignNavigation from "./CampaignNavigation";

// Updated VendorDashboard component
const VendorDashboard = () => {
  const { userDetails } = useAuthContext();
  
  return (
    <Container sx={{ marginTop: 3 }}>
      {userDetails ? (
  <Box>
    <Typography 
      variant="h4" 
      component="h6"
      textTransform={"capitalize"} 
      textAlign="center"
      sx={{ 
        fontSize: { xs: "1.5rem", sm: "2.125rem" }
      }}
    >
      Hello! {userDetails.firstName} {userDetails.lastName}
    </Typography>

    <Typography 
      variant="h5" 
      textAlign="center" 
      gutterBottom
      sx={{ 
        fontSize: { xs: "1.25rem", sm: "1.5rem" }
      }}
    >
      We are glad to have you here
    </Typography>
  </Box>
) : (
  <Box>
    <Skeleton 
      variant="text" 
      width="60%" 
      height={40} 
      sx={{ margin: "auto", marginBottom: 2 }} 
    />
    <Skeleton 
      variant="text" 
      width="50%" 
      height={30} 
      sx={{ margin: "auto" }} 
    />
  </Box>
)}
      <Box 
        sx={{ 
          display: "flex", 
          justifyContent: "center",
                   
        }}
      >
        {/* Add the campaign navigation component here */}
      <CampaignNavigation />
      </Box>
      
      <Dashboard />
    </Container>
  );
};

export default VendorDashboard;