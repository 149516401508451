import React from "react";
import { Box, styled, Tooltip, tooltipClasses, TooltipProps, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";

// Fix for Tooltip issue, make sure you're passing the correct component and props

const SpoTrueLogo = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const onClick = () => {
    navigate("/");
  };
  
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

  return (
    <Box onClick={onClick}sx={{mt:1}}>
      <LightTooltip title="Upload.Submit.Veriy">
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/spotrue.png`}
        alt=" SpoTrue "
        style={{ width:isMobile? 90:200, justifyContent:"center",}}
      />
      </LightTooltip>
    </Box>
  );
};

export default SpoTrueLogo;
