import React from "react";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Paper,
  Fade,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import SpeedIcon from "@mui/icons-material/Speed";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { useTheme } from "@mui/material/styles";

const FeaturesSection: React.FC = () => {
  const theme = useTheme(); // Access the custom theme

  return (
    <Grid container spacing={4} py={6}>
      {/* Left Content */}
      <Grid item xs={12} md={6}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "1.8rem", md: "2.5rem" },
              mb: 2,
              background: theme.palette.text.primary,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Helping you focus on what really matters
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              mb: 3,
              fontSize: { xs: "1rem", md: "1.2rem" },
              lineHeight: 1.6,
            }}
          >
            By leveraging Spotrue's AI and ML capabilities, you can confidently
            ensure the accuracy of your transit advertising campaigns, saving
            time and preventing fraud.
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
            >
              <CheckCircleIcon color="primary" /> Easy to use
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
            >
              <CheckCircleIcon color="primary" /> Accurate results
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
            >
              <CheckCircleIcon color="primary" /> Smart audit features
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                fontSize: "1.1rem",
                fontWeight: 500,
              }}
            >
              <CheckCircleIcon color="primary" /> Secure platform
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* Right Content */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            position: "relative",
            height: "400px",
            bgcolor: `${theme.palette.primary.light}1a`, // Light background with primary color
            borderRadius: "24px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Central visualization */}
          <Box
            sx={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: `0 8px 32px ${theme.palette.primary.light}`,
            }}
          >
            <AutoGraphIcon
              sx={{ fontSize: "4rem", color: theme.palette.common.white }}
            />
          </Box>

          {/* Feature Cards */}
          <Fade in timeout={1000}>
            <Paper
              elevation={3}
              sx={{
                position: "absolute",
                width: "250px",
                background: theme.palette.background.paper,
                borderRadius: "16px",
                padding: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "all 0.3s ease",
                top: "10%",
                left: "5%",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: `0 8px 24px ${theme.palette.primary.dark}`,
                },
              }}
            >
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary.light,
                  width: 48,
                  height: 48,
                }}
              >
                <TouchAppIcon sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box>
                <Typography fontWeight={700} sx={{ mb: 0.5 }}>
                  Easy to use
                </Typography>
                <Typography fontSize="0.875rem" color="text.secondary">
                  Made for everyone
                </Typography>
              </Box>
            </Paper>
          </Fade>

          <Fade in timeout={1000}>
            <Paper
              elevation={3}
              sx={{
                position: "absolute",
                width: "250px",
                background: theme.palette.background.paper,
                borderRadius: "16px",
                padding: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
                transition: "all 0.3s ease",
                bottom: "10%",
                right: "5%",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: `0 8px 24px ${theme.palette.primary.dark}`,
                },
              }}
            >
              <Avatar
                sx={{
                  bgcolor: theme.palette.primary.light,
                  width: 48,
                  height: 48,
                }}
              >
                <SpeedIcon sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box>
                <Typography fontWeight={700} sx={{ mb: 0.5 }}>
                  Smart audit features
                </Typography>
                <Typography fontSize="0.875rem" color="text.secondary">
                  Audit tool you need
                </Typography>
              </Box>
            </Paper>
          </Fade>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FeaturesSection;
