import { PaletteOptions } from '@mui/material';
import { common, grey } from '@mui/material/colors';

const palette: PaletteOptions = {
  mode: 'light',
  background: {
    default: common.white,
    paper: '#F1F2F4', // A light grayish color for paper background
  },
  text: {
    primary: grey[900], // Dark text for primary
    secondary: '#717171', // Lighter grey for secondary text
    disabled: grey[500], // Grey for disabled text
  },
  primary: {
    main: '#1976d2', // Custom primary color (e.g., teal)
    light: '#48a999', // Lighter shade of primary
    dark: '#5e92f3',  // Darker shade of primary
    contrastText: common.white, // Ensure good contrast for text on primary buttons/areas
  },
  secondary: {
    main: '#1976d2', // Custom secondary color (blue)
    light: '#5e92f3', // Lighter blue
    dark: '#01579b', // Darker blue
    contrastText: common.white, // Ensure good contrast for text on secondary areas
  },
  // Optionally, you can add other custom colors like success, error, etc.
  success: {
    main: '#4caf50',
  },
  error: {
    main: '#f44336',
  },
  warning: {
    main: '#ff9800',
  },
  info: {
    main: '#2196f3',
  },
};

export default palette;
