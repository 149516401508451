import React, { useState } from "react";
import {
  Box,
  Card,
  Avatar,
  Typography,
  Button,
  Chip,
  TablePagination,
  CircularProgress,
  IconButton,
  Snackbar,
  Grid,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { FileData } from "./Dashboard";
import { camelCaseToTitleCase } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
interface RequestDetailsCardProps {
  filesData: FileData[];
  handleCreateRequest: () => void;
  isLoading: boolean;
}

const RequestDetailsCard: React.FC<RequestDetailsCardProps> = ({
  filesData,
  handleCreateRequest,
  isLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isMobileView = useMediaQuery("(max-width:599px)");
  const navigate = useNavigate();

  const sortedFilesData = [...filesData].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  const paginatedFilesData = sortedFilesData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleClick = (
    gogigRequestId: string,
    campaignVehicleClass: string
  ) => {
    navigate(`/audit-process/${gogigRequestId}`);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const getStatusBackgroundColor = (status?: string, clientUrl?: string) => {
    switch (status) {
      case "completed":
      case "requestApproved":
        return "#d4edda"; // Green for positive statuses
      case "requestCreated":
        return clientUrl === null ? "#D32F2F" : "#d1ecf1";
      case "requestCreationPending":
        return "#D32F2F";
      case "processing":
      case "requestUnderReview":
      case "resultsUnderReview":
        return "#fff7d8"; // Yellow for ongoing statuses
      case "rejected":
        return "white"; // Red for rejected statuses
      default:
        return "#fefefe"; // Default to warning for unknown statuses
    }
  };
  const getStatusColor = (status?: string, clientUrl?: string) => {
    switch (status) {
      case "completed":
      case "requestApproved":
        return "#3b7c4d"; // Green for positive statuses
      case "requestCreated":
        return clientUrl === null ? "white" : "#31708e";
      case "requestCreationPending":
        return "white";
      case "processing":
      case "requestUnderReview":
      case "resultsUnderReview":
        return "#ffd114"; // Yellow for ongoing statuses
      case "rejected":
        return "#D32F2F"; // Red for rejected statuses
      default:
        return "#808080"; // Default to warning for unknown statuses
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mt: isMobileView ? 2 : 0 }} gutterBottom>
        Uploaded Campaigns
      </Typography>

      {/* Display loading state with Card */}
      {isLoading ? (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            boxShadow: 3,
          }}
        >
          <CircularProgress />
        </Card>
      ) : filesData?.length ? (
        paginatedFilesData.map((file, index) => (
          <Card
            key={index}
            variant="outlined"
            elevation={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              padding: 2,
              backgroundColor: "white",
              boxShadow: 3,
              cursor: "pointer", // Indicate clickable
              transition: "transform 0.2s, box-shadow 0.2s", // Smooth hover effect
              "&:hover": {
                transform: "scale(1.02)", // Slight zoom
                boxShadow: 3, // Enhanced shadow on hover
                backgroundColor: "#f9f9f9", // Optional: Slight background change
              },
            }}
            onClick={() =>
              handleClick(file.gogigRequestId, file.campaignVehicleClass)
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" mt={1}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Campaign Name: 
                  </Typography>
                  <Typography
                      variant="body2"
                      sx={{ml:1}}
                      color="text.secondary">{file.campaignName || "Untitled Campaign"}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap", // Prevent wrapping
                        maxWidth: "200px", // Adjust max width as needed
                        mr: 1, // Margin to separate from the value
                      }}
                    >
                      Request ID:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        wordWrap: "break-word", // Ensures long words wrap
                        whiteSpace: "normal",
                        width: { md: "180px", sm: "300px" },
                      }}
                    >
                      {file.gogigRequestId}
                    </Typography>
                    <Tooltip title="Copy Request ID" arrow>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleCopyToClipboard(file.gogigRequestId);
                        }}
                      >
                        <ContentCopyRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box display="flex" alignItems="center" mt={1}>
                    <AccessTimeIcon sx={{ color: "text.secondary", mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      {file.uploadDate} at {file.uploadTime}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                display={"flex"}
                alignItems="center"
              >
                <Typography  variant="subtitle1"
                      fontWeight="bold"  mr={1}>
                  Request Status:
                </Typography>
                <Chip
                  label={camelCaseToTitleCase(
                    file.clientUrls === null
                      ? "Request Creation Pending"
                      : file.requestStatus
                  )}
                  sx={{
                    color: getStatusColor(file.requestStatus, file.clientUrls),
                    border: "1px solid #ffd114",
                    backgroundColor: getStatusBackgroundColor(
                      file.requestStatus,
                      file.clientUrls
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12} // Full width on tablet
                md={
                  file.clientUrls === null ||
                  file.requestStatus === "requestCreationPending"
                    ? 12
                    : 4
                } // Full width on PC
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: { xs: 2, sm: 0 },
                  width: "100%", // Ensure full width for larger screens
                }}
              >
                {file.resultUrl ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<DownloadIcon />}
                    href={file.resultUrl}
                    download
                    target="_blank"
                    sx={{
                      width: "100%", // Full width
                      maxWidth: "100%", // Ensure no constraint on width
                    }}
                  >
                    Download
                  </Button>
                ) : file.clientUrls === null ||
                  file.requestStatus === "requestCreationPending" ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={2}
                    sx={{
                      width: "100%", // Full width
                      maxWidth: "100%", // Ensure no constraint on width
                      p: 2,
                      bgcolor: "#fff7d8", // Light yellow background
                      borderRadius: 1,
                      border: "1px solid #ffd114",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <PendingActionsIcon
                        sx={{
                          color: "#8B6F1F", // Match text color
                          fontSize: 20, // Adjust size as needed
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "#8B6F1F",
                          fontWeight: "bold",
                        }}
                      >
                        Request Creation Pending
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "center",
                        color: "#5C4813",
                      }}
                    >
                      {file.requestStatus === "requestCreationPending"
                        ? "Your request is pending as files are uploaded but not submitted. Click 'COMPLETE YOUR REQUEST' to proceed."
                        : "Your request is currently pending. To proceed, kindly click the 'COMPLETE YOUR REQUEST' button and upload your files."}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/upload-createRequest/2/${file.campaignVehicleClass}/${file.gogigRequestId}`
                        );
                      }}
                      sx={{
                        mt: 1,
                        bgcolor: "#3b7c4d", // Custom green color
                        "&:hover": {
                          bgcolor: "#2d5e3a",
                        },
                      }}
                    >
                      complete Your Request
                    </Button>
                  </Box>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      textAlign: "center", // Center text
                      px: 2,
                    }}
                  >
                    Audit in progress. The report will be available for download
                    once completed.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        ))
      ) : (
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 3,
            boxShadow: 3,
          }}
        >
          <Avatar
            sx={{
              bgcolor: "primary.light",
              width: 60,
              height: 60,
              margin: "0 auto",
              color: "primary.main",
            }}
          >
            <InsertDriveFileIcon sx={{ fontSize: 30 }} />
          </Avatar>
          <Typography variant="h6" color="text.secondary" mt={2}>
            No files found
          </Typography>
          <Typography variant="body2" color="text.secondary" mb={3}>
            You haven't uploaded any files yet. Create a request to get started.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleCreateRequest}
          >
            Create New Campaign
          </Button>
        </Card>
      )}

      {/* Pagination */}
      {filesData.length > 10 && (
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={sortedFilesData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {/* Snackbar for confirmation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="ID copied to clipboard!"
      />
    </Box>
  );
};

export default RequestDetailsCard;
