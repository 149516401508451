import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Grid
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import theme from '../../theme';

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "How does Spotrue ensure the accuracy of my transit media audits?",
    answer: "Spotrue leverages advanced AI and ML algorithms to meticulously analyze each data point in your campaign. We cross-check branding data with real-time images and information to deliver precise and reliable audit results."
  },
  {
    question: "How long does it take to audit a campaign with Spotrue?",
    answer: "The time required depends on the size of your campaign. Spotrue is optimized to handle large-scale data efficiently, and typically, audits are completed within a few days, even for campaigns involving thousands of media options."
  },
  {
    question: "Can Spotrue integrate with my existing campaign management tools?",
    answer: "Yes, Spotrue is designed to seamlessly integrate with your existing tools, including Excel and PowerPoint. You can easily upload your campaign data, and our tool will process it without requiring any additional software."
  },
  {
    question: "How can I verify the audit results provided by Spotrue?",
    answer: "Spotrue provides detailed reports with every audit, including the percentage of tampered data, specific vehicle registration numbers, and reasons for discrepancies. You can review these reports directly within our platform."
  },
  {
    question: "What kind of reports does Spotrue generate?",
    answer: "Spotrue offers three types of reports: Basic (percentage of tampered vehicles), Advanced (with vehicle registration numbers), and Pro (detailed reasoning)."
  },
  {
    question: "Is there a trial version of Spotrue available?",
    answer: "Yes, Spotrue offers a 100 free credits for your latest campaign. You can upload your data, and we'll provide a comprehensive audit to demonstrate the accuracy and effectiveness of our tool."
  },
  {
    question: "Does Spotrue support large-scale campaigns?",
    answer: "Absolutely. Spotrue is built to scale with your business, whether you're auditing a few hundred medias or several thousand. Our platform ensures accurate results, regardless of campaign size."
  }
];

const FAQSection: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" sx={{py:6 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="overline" color="primary" sx={{ mb: 1 }}>
          FAQS
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: "bold", color:  theme.palette.text.primary }}>
          Frequently Asked Questions
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {faqs.map((faq, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  bgcolor: "#f9fafc",
                  borderRadius: 2,
                  "&:before": {
                    display: "none",
                  },
                  p:2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <AccordionSummary
                  expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                  sx={{
                    px: 0,
                    "& .MuiAccordionSummary-content": {
                      my: 1,
                    },
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0, pb: 2 }}>
                  <Typography color="text.secondary">{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FAQSection;