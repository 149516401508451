import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { AuditRequest } from "../types";

interface CampaignDetailsProps {
  requestDetails?: AuditRequest;
  campaignName: string;
  location: string;
  campaignStartDate: string;
  campaignEndDate: string;
  fieldInfo: { [key: string]: string };
  errorMessage: string | null;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNextStep: () => void;
  loading: boolean;
}

const CampaignDetailsComponent: React.FC<CampaignDetailsProps> = ({
  requestDetails,
  campaignName,
  location,
  campaignStartDate,
  campaignEndDate,
  fieldInfo,
  errorMessage,
  handleInputChange,
  handleNextStep,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [popperContent, setPopperContent] = useState<string>("");
  const [open, setOpen] = useState(false);
  const formattedStartDate = campaignStartDate 
  ? new Date(campaignStartDate).toISOString().split("T")[0]
  : "";

const formattedEndDate = campaignEndDate 
  ? new Date(campaignEndDate).toISOString().split("T")[0]
  : "";
  const handlePopperOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    content: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopperContent(content);
    setOpen(true);
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
    setPopperContent("");
    setOpen(false);
  };

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
          fontWeight: 500,
        }}
      >
        
        {requestDetails?.allowRequestEdit ? "Update" : "Add"} campaign details,
        including campaign name, location, duration.
      </Typography>

      <Grid container spacing={2}>
        {/* Campaign Name */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            label="Campaign Name"
            variant="outlined"
            fullWidth
            required
            value={campaignName}
            name="campaignName"
            error={!!errorMessage}
            helperText={errorMessage}
            onChange={handleInputChange}
            sx={{
              '& .MuiInputLabel-asterisk': {
                color: 'red', // Set the asterisk color to red
              },
              '& .MuiFormHelperText-root': {
                marginTop: 0, // Reduce helper text margin
              },
              height: '72px', // Ensure consistent height for all fields
            }}
          />
          <InfoIcon
            sx={{ ml: 1, color: "info.main", cursor: "pointer" }}
            onMouseEnter={(e) => handlePopperOpen(e, fieldInfo.campaignName)}
            onMouseLeave={handlePopperClose}
          />
        </Grid>

        {/* Location */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            label="Location"
            name="location"
            variant="outlined"
            fullWidth
            value={location}
            onChange={handleInputChange}
            sx={{
              height: '72px',
              '& .MuiFormHelperText-root': {
                marginTop: 0, // Reduce helper text margin
              },
            }}
          />
          <InfoIcon
            sx={{ ml: 1, color: "info.main", cursor: "pointer" }}
            onMouseEnter={(e) => handlePopperOpen(e, fieldInfo.location)}
            onMouseLeave={handlePopperClose}
          />
        </Grid>

        {/* Start Date */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center"}}
        >
          <TextField
            label="Start Date"
            type="date"
            name="campaignStartDate"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={formattedStartDate}
            onChange={handleInputChange}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            sx={{
              height: '72px',
              '& .MuiFormHelperText-root': {
                marginTop: 0, // Reduce helper text margin
              },
            }}
          />
          <InfoIcon
            sx={{ ml: 1, color: "info.main", cursor: "pointer" }}
            onMouseEnter={(e) =>
              handlePopperOpen(e, fieldInfo.campaignStartDate)
            }
            onMouseLeave={handlePopperClose}
          />
        </Grid>

        {/* End Date */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <TextField
            label="End Date"
            type="date"
            name="campaignEndDate"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={formattedEndDate}
            onChange={handleInputChange}
            inputProps={{
              min: campaignStartDate || undefined,
              max: new Date().toISOString().split("T")[0],
            }}
            sx={{
              height: '72px',
              '& .MuiFormHelperText-root': {
                marginTop: 0, // Reduce helper text margin
              },
            }}
          />
          <InfoIcon
            sx={{ ml: 1, color: "info.main", cursor: "pointer" }}
            onMouseEnter={(e) => handlePopperOpen(e, fieldInfo.campaignEndDate)}
            onMouseLeave={handlePopperClose}
          />
        </Grid>
      </Grid>

      {/* Popper */}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 8], // Adjusts the offset to move it slightly away from the anchor
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundary: "viewport", // Ensures the Popper doesn't go out of the visible area
            },
          },
        ]}
      >
        <Box
          sx={{
            padding: 1,
            backgroundColor: "#f5f5f5",
            border: "1px solid #d3d3d3",
            borderRadius: 1,
            boxShadow: 2,
            mr:1,
            maxWidth: 200,
            zIndex: 1300, // Ensures it overlays everything else
          }}
        >
          <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
            {popperContent}
          </Typography>
        </Box>
      </Popper>

      {/* Highlight the Next Step */}
      <Box
        sx={{
          marginTop: 2,
          padding: 2,
          backgroundColor: "#f9f9f9",
          border: "1px solid #d3d3d3",
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: 500, marginBottom: 1, color: "#333" }}
        >
          <strong>Almost there!</strong>
        </Typography>
        <Typography variant="body2" sx={{ color: "gray", fontSize: "0.95rem" }}>
          Once you finish filling in the campaign details, click on the button
          below to proceed to the final step:{" "}
          <strong>Uploading campaign files</strong>. This will complete your
          campaign setup!
        </Typography>
      </Box>

      {/* Proceed Button */}
      <Box sx={{ textAlign: "center", marginTop: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextStep}
          disabled={loading}
          sx={{
            paddingX: 4,
            paddingY: 1,
            fontWeight: "bold",
            fontSize: "0.95rem",
          }}
        >
          {loading ? "Processing..." : "Proceed to File Upload"}
        </Button>
      </Box>
    </>
  );
};

export default CampaignDetailsComponent;
