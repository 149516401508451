import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export interface CampaignDetails {

}
export interface FileUrls{
  clientUrls?:string
  clientComment?: string;
  campaignName?: string;
  location?: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  vehicleClass?: string;
  action?:string;
}

export async function createUserAuditRequest(
  authHeader: string,
  data: FileUrls ,

) {
  const payload = {
    clientUrls:data.clientUrls,
    clientComment: data.clientComment,
    campaignName: data.campaignName,
    campaignLocation: data.location,
    campaignStartTime: data.campaignStartDate,
    campaignEndTime: data.campaignEndDate,
    campaignVehicleClass: data.vehicleClass,
  };

  const url: string = `${SpotrueApiBaseUrl}/saveUserAuditRequest`; // Update endpoint based on cURL
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
    body: payload, // Ensure the payload is properly serialized as JSON
  });
}
