import React from "react";
import { Box, Button, Grid, Typography, Card, CardMedia } from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

// Custom Gradient Button using palette colors
const GradientButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.text.primary,
  padding: "12px 30px",
  "&:hover": {
    background:theme.palette.secondary.light,
  },
}));

interface HeroSectionProps {
  onRequestDemo: () => void; // The type for onRequestDemo function
}

const HeroSection: React.FC<HeroSectionProps>= ({ onRequestDemo }) => {
  const heading = "Next generation audit tool for your business";
  const description =
    "SpoTrue is a tool that can analyze the data and verify its authenticity.";
  return (
    <Grid container spacing={3}>
      {/* Text Content */}
      <Grid item xs={12} md={6}>
        <Box sx={{ mb: { xs: 3, md: 0 } }}>
          {/* Badge */}
          <Typography
            component="span"
            sx={{
              display: "inline-block",
              bgcolor: (theme) => theme.palette.background.paper,
              color: (theme) => theme.palette.text.primary,
              px: 2,
              py: 0.5,
              borderRadius: "20px",
              fontSize: "0.875rem",
              fontWeight: 600,
              mb: 2,
            }}
          >
            New product release →
          </Typography>

          {/* Heading */}
          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2rem", md: "3rem" },
              lineHeight: 1.2,
              mb: 2,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {heading}
          </Typography>

          {/* Description */}
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              mb: 3,
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
          >
            {description}
          </Typography>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              mb: 3,
            }}
          >
            <GradientButton
              variant="contained"
              onClick={onRequestDemo}
              sx={{
                maxWidth: { xs: "50%", sm: "200px" },
                color:"white"
              }}
            >
              Request Demo
            </GradientButton>
            {/* <Button
              variant="outlined"
              sx={{
                maxWidth: { xs: "50%", sm: "200px" },
                borderColor: (theme) => theme.palette.background.paper,
                color: (theme) => theme.palette.text.primary,
                "&:hover": {
                  borderColor: (theme) => theme.palette.background.paper,
                  background: (theme) => theme.palette.background.paper,
                },
              }}
            >
              Learn More
            </Button> */}
          </Box>

          {/* Rating */}
          <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
            ★★★★★ 100+ users
          </Typography>
        </Box>
      </Grid>

      {/* Video */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            paddingTop: "56.25%", // 16:9 Aspect ratio
          }}
        >
          <Card
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: (theme) => theme.palette.background.paper,
              borderRadius: 1,
            }}
          >
            <CardMedia
              component="video"
              src={`${process.env.PUBLIC_URL}/images/logos/spotrue_animated_logo.mp4`}
              title="Demo Video"
              controls
              sx={{
                width: "100%",
                height: "100%",
                border: `1px solid ${grey[200]}`,
              }}
            />
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeroSection;
