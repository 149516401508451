export interface SignUpValues {
  firstName:string;
  lastName:string;
  emailId: string;
  password: string;

  }
  
  const initialSignUpValues: SignUpValues = {
    firstName:'',
    lastName:'',
    emailId:'',
    password: '',
    
  };
  
  export default initialSignUpValues;