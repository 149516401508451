import {
  Card,
  Typography,
  Divider,
  Grid,
  Box,
  Button,
  Tooltip,
  Snackbar,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { AuditRequest } from "./vehicleAuditComponents/types";
import AuditRequestComment from "./AuditRequestComment";
import { FilePresent } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

interface AuditRequestDetailsCardProps {
  fileData: AuditRequest | undefined;
  fileDetails: {
    name: string;
    url: string;
  }[];
}

const AuditRequestDetailsCard: React.FC<AuditRequestDetailsCardProps> = ({
  fileData,
  fileDetails,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleViewReport = (resultUrl: string | undefined) => {
    if (!resultUrl) {
      alert("No report available to view.");
      return;
    }

    // Open the report in a new tab or download it directly
    const link = document.createElement("a");
    link.href = resultUrl;
    link.target = "_blank"; // Open in a new tab
    link.download = "Audit_Report.pdf"; // Optional: specify download name
    link.click();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: 2, // Better spacing between cards
        width: "100%",
      }}
    >
      {/* Main Details Card */}
      <Card
        variant="outlined"
        sx={{
          padding: 3,
          borderRadius: "8px",
          boxShadow: 4,
          backgroundColor: "#f9f9f9",
          width: {
            sx: "100%",
            md: fileData?.allowRequestEdit ? "50%" : "100%",
            sm: fileData?.allowRequestEdit ? "50%" : "100%",
          },
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            color: "#333",
          }}
        >
          Audit Request Details
        </Typography>

        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3}>
          {/* Left Column: Campaign Details */}
          <Grid item xs={12} sm={12} md={6}>
            <Box>
              {/* Campaign Name */}
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                
              >
                Campaign Name
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                {fileData?.campaignName || "N/A"}
              </Typography>
              {fileData?.gogigRequestId && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="subtitle1"
                      fontWeight="bold"
                  >
                    Request Id
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      gutterBottom
                    >
                      {fileData?.gogigRequestId || "N/A"}
                    </Typography>

                    <Tooltip title="Copy Request ID" arrow sx={{ ml: 1 }}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card click
                          handleCopyToClipboard(fileData.gogigRequestId);
                        }}
                      >
                        <ContentCopyRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={2000}
                      onClose={handleSnackbarClose}
                      message="ID copied to clipboard!"
                    />
                  </Box>
                </Box>
              )}

              {/* Vehicle Type */}
              <Typography
                variant="subtitle1"
                      fontWeight="bold"
                
              >
                Vehicle Type
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {fileData?.campaignVehicleClass || "N/A"}
              </Typography>
            </Box>
          </Grid>

          {/* Right Column: Uploaded Files */}
          <Grid item xs={12} sm={12} md={fileData?.allowRequestEdit ? 12 : 6}>
            {fileData?.resultUrl ? (
              <Box>
                <Typography
                 variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Download Your Campaign Audit Report
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => handleViewReport(fileData.resultUrl)}
                  sx={{ mt: 2 }}
                >
                  Download
                </Button>
              </Box>
            ) : (
              fileDetails.length > 0 && (
                <Box>
                  <Typography
                   variant="subtitle1"
                    gutterBottom
                    sx={{ fontWeight: "bold"}}
                  >
                    Uploaded Files
                  </Typography>
                  <Grid container spacing={2}>
                    {fileDetails.map((file, index) => {
                      return (
                        <Grid item key={index} xs={12} sm={12} md={12}>
                          <Typography
                            component="a"
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              fontSize: { xs: "14px", sm: "16px" },
                              color: "#007BFF",
                              wordWrap: "break-word",
                              overflowWrap: "anywhere",
                              textDecoration: "none",
                              "&:hover": {
                                backgroundColor: "#E6F2FF",
                                color: "#0056b3",
                              },
                            }}
                          >
                            <FilePresent
                              sx={{ fontSize: 25, marginRight: 1 }}
                            />
                            {file.name}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Card>

      {/* Comment Card (Only if allowRequestEdit is true) */}
      {fileData?.allowRequestEdit ? (
        <Card
          variant="outlined"
          sx={{
            padding: 3,
            borderRadius: "8px",
            boxShadow: 4,
            backgroundColor: "#f9f9f9",
            width: {
              sx: "100%",
              md: "50%",
              sm: "50%",
            },
            ml: { md: 1, sm: 1 },
            mt: { xs: 1, md: 0, sm: 0 },
          }}
        >
          <AuditRequestComment
            campaignType={fileData.campaignVehicleClass}
            gogigReqId={fileData?.gogigRequestId}
            reviewComment={fileData?.reviewComment}
          />
        </Card>
      ) : null}
    </Box>
  );
};

export default AuditRequestDetailsCard;
