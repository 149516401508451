import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import ExternalVendorContainer from "./components/header/ExternalVendorConatiner";
import { SpotrueFooter } from "./components/spotrue-footer-file";

function VendorContainer() {
  const headerSize = "61px";
  return (
    <Box className={"layout-container"}>
      <ExternalVendorContainer />
      <Box style={{ marginTop: headerSize }}>
        <Outlet />
      </Box>
      <SpotrueFooter />
    </Box>
  );
}

export default VendorContainer;
