import React from 'react';
import { Box, Typography, Card, Grid, Stack } from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import WebIcon from '@mui/icons-material/Web';
import StarIcon from '@mui/icons-material/Star';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';

interface MetricCardProps {
  icon: React.ReactNode;
  value: string;
  label: string;
}

const MetricCard: React.FC<MetricCardProps> = ({ icon, value, label }) => (
  <Card 
    sx={{ 
      p: 3, 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 1,
      boxShadow: 'none',
      bgcolor: 'background.default'
    }}
  >
    <Box 
      sx={{ 
        p: 1.5,
        borderRadius: '50%',
        bgcolor: 'primary.50',
        color: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {icon}
    </Box>
    <Typography variant="h4" fontWeight="bold">
      {value}
    </Typography>
    <Typography variant="body1" color="text.secondary">
      {label}
    </Typography>
  </Card>
);

interface FeatureItemProps {
  text: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ text }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <CheckIcon sx={{ color: 'primary.main' }} />
    <Typography>{text}</Typography>
  </Stack>
);

const MetricsSection: React.FC = () => {

  return (
    <Grid container spacing={4}  py={6}>
      {/* Metrics Cards */}
      <Grid item xs={12} md={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} display="flex" justifyContent="space-between" flexDirection={{ xs: 'row', sm: 'row' }}>
            <MetricCard
              icon={<CloudIcon />}
              value="100+"
              label="Verified Campaigns"
            />
            <MetricCard
              icon={<StarIcon />}
              value="5 star"
              label="User reviews"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} display="flex" justifyContent="space-between" flexDirection={{ xs: 'row', sm: 'row' }}>
            <MetricCard
              icon={<WebIcon />}
              value="100%"
              label="Accurate Results"
            />
            <MetricCard
              icon={<LanguageIcon />}
              value="845+"
              label="Clients Served"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Features Section */}
      <Grid item xs={12} md={6}>
        <Box sx={{ pl: { md: 4 } }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
            Designed to scale when your business grows
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            Spotrue's platform is built to scale with your campaigns, ensuring every
            branding is accurately verified, no matter the size of your operation.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <FeatureItem text="Designed to scale" />
                <FeatureItem text="Verified reporting" />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <FeatureItem text="Advanced AI & ML algorithms" />
                <FeatureItem text="Comprehensive audit features" />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MetricsSection;
