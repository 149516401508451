import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, IconButton, useTheme } from "@mui/material";
import { useAuthContext } from "../../providers/AuthProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuditProfileMenu from "./AuditProfileMenu";
import { useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";
import SpoTrueLogo from "../logo/spotrueLogo";
import AuditSideNavigation from "../navigation/AuditSideNavigation";

const ExternalVendorContainer = () => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const { breakpoints } = useTheme();
  const history = useNavigate();
  const matchMobileView = useMediaQuery(breakpoints.down("md"));
  const closeMobileMenu = () => {
    setVisibleMenu(false);
  };
  const handleLogin = () => {
    history("/login");
  };
  
  const handleDashboardClick = () => {
    history("/dashboard");
  };

  const [isNavOpen, setIsNavOpen] = useState(false);
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const { userDetails, isAuthenticated } = useAuthContext();

  return (
    <header
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "#F5F7FA",
      }}
    >
      <Container sx={{ py: { xs: 1, md: 1 } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            {matchMobileView && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={toggleNav}
                className="menu-icon"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ marginLeft: matchMobileView ? "10px" : 0,   }}>
              <SpoTrueLogo />
              
            </Box>
          </Box>
          <Box>
            {matchMobileView && (
              <>
                
                {!isAuthenticated && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    sx={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    <LoginIcon sx={{ mr: 1 }} />
                    LOGIN / SIGNUP
                  </Button>
                )}
              </>
            )}
          </Box>
           
          <AuditSideNavigation
            profileData={userDetails || undefined}
            isOpen={isNavOpen}
            onClose={toggleNav}
          />
        
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              transition: (theme) => theme.transitions.create(["top"]),
              ...(matchMobileView && {
                py: 6,
                backgroundColor: "background.paper",
                zIndex: "appBar",
                position: "fixed",
                height: "auto",
                top: visibleMenu ? 0 : "-120vh",
                left: 0,
              }),
            }}
          >
            {!visibleMenu && !matchMobileView && (
              <>
              
                <Box sx={{ marginLeft: "auto" }} className="menu-icon">
                <Button
                    onClick={handleDashboardClick}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "none",
                      mr: 2
                    }}
                  >
                    <DashboardIcon sx={{ mr: 1 }} />
                    Dashboard
                  </Button>
                  
                </Box>
                <AuditProfileMenu
                    profileData={userDetails}
                    closeMobileMenu={closeMobileMenu}
                    onClick={closeMobileMenu}
                  />
                 
                {!isAuthenticated && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    sx={{
                      backgroundColor: "#1976d2",
                      color: "#fff",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    <LoginIcon sx={{ mr: 1 }} />
                    LOGIN / SIGNUP
                  </Button>
                )}
              </>
            )}
          </Box>
        </Box>
      </Container>
    </header>
  );
};

export default ExternalVendorContainer;