import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useAuthContext } from "../../providers/AuthProvider";
import {
  createUserAuditRequest,
  FileUrls,
} from "../../clients/createUserAuditRequest";
import useFileUpload from "../../hook/FileUploadHook";
import GlobalSnackbar from "../../components/snackbarComponent/Snackbar";
import { uploadFileWithMetadata } from "../../clients/uploadFileWithMetaData";
import AuditRequestForm from "./vehicleAuditComponents/AuditCreationComponents/AuditRequestComponent";
import { formatFileName } from "../../utils/formatFileName";
import { useNavigate, useParams } from "react-router-dom";
import { getAuditRequestById } from "../../clients/getAuditRequestById";
import { AuditRequest } from "./vehicleAuditComponents/types";
import { updateUserAuditRequest } from "../../clients/updateUserAuditRequest";
import deleteFileFromS3 from "../../clients/deleteFileFromS3";
import removeClientUrl from "../../clients/removeClientUrl";
import getFileData from "../../clients/getFileData";
import { getMimeTypeExcel } from "../../components/vehicleRCValidation/Utils";
import { v4 as uuidv4 } from "uuid";

interface AuditRequestPayload {
  clientUrls?: (string | null)[];
  comment?: string;
  campaignName?: string;
  location?: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  campaignType?: string;
  action?: string;
}
export interface FileDataPayload {
  originalFileName: string;
  contentType: string;
}
interface UploadResponse {
  data: {
    fileData?: {
      fileUrl: string;
    };
  };
}
export type SelectedFile = {
  file: File;
  uuid: string;
  name?: string;
  size?: number;
  type?: string;
};

const UploadAndCreateRequest = () => {
  const { authHeader, userDetails } = useAuthContext();
  const navigate = useNavigate();
  const { gogigReqId } = useParams<{ gogigReqId: string }>();
  const [comment, setComment] = React.useState<string>("");
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {}
  );
  const [deletingIndex, setDeletingIndex] = useState<string | null>(null);
  const deletingIndexRef = useRef<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [severity, setSeverity] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const { uploadFile, loading, setLoading, error } = useFileUpload(
    setUploadProgress,
    deletingIndexRef,
    setDeletingIndex,
    setSnackbarOpen,
    setSnackbarMessage,
    setSelectedFiles,
    selectedFiles,
  );
  const [isFileUpload, setIsFileUpload] = useState<boolean | null>(null);
  const [campaignName, setCampaignName] = useState<string>("");
  const [campaignStartDate, setCampaignStartDate] = useState<string>("");
  const [campaignEndDate, setCampaignEndDate] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [gogigRequestId, setGogigRequestId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [requestDetails, setRequestDetails] = useState<AuditRequest>();
  const { stage, campaignType } = useParams<{
    stage: string;
    campaignType: string;
  }>();
  const stepNumber = stage ? parseInt(stage, 10) : 1;
  const [step, setStep] = useState<number>(stepNumber);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [uploadedUrls, setUploadedUrls] = useState<(string | null)[]>();
  const [combinedUrls, setCombinedUrls] = useState<string | undefined>(
    requestDetails?.clientUrls
  );

  useEffect(() => {
    deletingIndexRef.current = deletingIndex;
  }, [deletingIndex]);
  const handleNextStep = async () => {
    if (!campaignName.trim()) {
      setErrorMessage("Campaign Name is required.");
      setSnackbarMessage("Campaign Name is required");
      setSnackbarOpen(true);
      setSeverity(true);
      return;
    }
    try {
      await handleSaveCampaignDetails({
        campaignName,
        location,
        campaignStartDate,
        campaignEndDate,
        campaignType,
      });

      setStep(2);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gogigReqId) {
          const response = await getAuditRequestById(authHeader, gogigReqId);
          const data = response as AuditRequest;
          setRequestDetails(data);

          if (data.clientUrls?.trim()) {
            setCombinedUrls(data?.clientUrls);
            const urls = data.clientUrls.split(", ");
            setUploadedUrls(urls);

            const fileArray = urls.map(async (url, index) => {
              const fileName = url.split("/").pop() || `file-${index}`; // Fallback to a default name
              const res = await getFileData(authHeader, fileName);
              const data = (res.data as { fileData: FileDataPayload }).fileData;
              const mimeType = getMimeTypeExcel(data.originalFileName); // Determine MIME type
              const fileContent = `File link: ${url}`; // Placeholder content for the file
              // Create a Blob and then a File
              return {
                file: new File([fileContent], data.originalFileName, {
                  type: mimeType,
                }),
                uuid: uuidv4(), // Add a unique identifier
              };
            });
            const resolvedFiles = await Promise.all(fileArray);
            setSelectedFiles(() =>
              resolvedFiles.map((file) => ({
                ...file, // Spread the existing file properties
                uuid: uuidv4(), // Add a unique identifier
              }))
            );
          }
          setCampaignName(data?.campaignName);
          setLocation(data?.campaignLocation);
          setCampaignStartDate(data?.campaignStartTime);
          setCampaignEndDate(data?.campaignEndTime);
          setComment(data?.clientComment);
        }
      } catch (error) {
        console.error("Error fetching audit request:", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "campaignName":
        if (value.trim()) {
          setErrorMessage(null);
        }
        setCampaignName(value);
        break;
      case "location":
        setLocation(value);
        break;
      case "campaignStartDate":
        setCampaignStartDate(value);
        break;
      case "campaignEndDate":
        setCampaignEndDate(value);
        break;
      default:
        break;
    }
  };

  const validateFiles = (
    files: File[],
    allowedTypes: string[],
    maxFiles: number
  ) => {
    const validFiles = files.filter((file) => allowedTypes.includes(file.type));

    if (validFiles.length > maxFiles) {
      throw new Error(`You can only upload up to ${maxFiles} files.`);
    }

    return validFiles;
  };

  const formatFileNames = (files: File[]) => {
    return files.map((file) => {
      const formattedFileName = formatFileName(file); // Assume this is defined elsewhere
      // Create the File object with the formatted name
      const newFile = new File([file], formattedFileName, { type: file.type });
      // Add a uuid to the file object
      return {
        file: newFile,
        uuid: uuidv4(),
        name: formattedFileName,
        size: file.size,
        type: file.type,
      };
    });
  };

  const uploadSingleFile = async (
    file: File,
    actualIndex: number,
    uuid: string,
    authHeader: string
  ) => {
    try {
      if (
        [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(file.type)
      ) {
        const formData = new FormData();
        formData.append("excel sheet", file);
        const metadata = {
          fileName: file.name,
          uploaderName: `Gogig Admin ${userDetails?.firstName} ${userDetails?.lastName}`,
        };
        formData.append("tags", "AUDIT DATA");
        formData.append("metadata", JSON.stringify(metadata));
        simulateUpload(uuid);

        const res = (await uploadFileWithMetadata(
          authHeader,
          formData
        )) as UploadResponse;
        return res?.data?.fileData?.fileUrl ?? null;
      } else {
        return await uploadFile(uuid, file);
      }
    } catch (error) {
      console.error(`Error uploading file ${file.name}:`, error);
      throw new Error(`Error uploading file: ${file.name}`);
    }
  };

  const handleFileSelection = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    setLoading(true);
    if ((!gogigReqId && !gogigRequestId) || !authHeader || !files) return;
    const allowedTypes = [
      "application/pdf",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/x-zip-compressed",
      "application/zip",
    ];
    const selectedFileArray = Array.from(files);
    try {
      const validFiles = validateFiles(
        selectedFileArray,
        allowedTypes,
        4 - selectedFiles.length
      );
      const formattedFiles: SelectedFile[] = formatFileNames(validFiles);

      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...formattedFiles, // Directly spread the formatted files array
      ]);

      const urls = await Promise.all(
        formattedFiles.map(async (file, index) => {
          const actualIndex = selectedFiles.length + index;
          const url = await uploadSingleFile(
            file.file,
            actualIndex,
            file.uuid,
            authHeader
          );
          if (url) {
            setCombinedUrls((prevUrls) =>
              prevUrls ? `${prevUrls}, ${url}` : url
            );
            const payload: FileUrls = {
              clientUrls: combinedUrls ? `${combinedUrls}, ${url}` : url,
            };
            setIsFileUpload(true);
            await updateUserAuditRequest(
              authHeader,
              gogigReqId ?? gogigRequestId,
              payload
            );
            setUploadProgress((prev) => ({ ...prev, [file.uuid]: 100 }));
            setSnackbarMessage(`${file.name} Successfully uploaded`);
            setSeverity(false);
            setSnackbarOpen(true);
            return url;
          }
          return null;
        })
      );

      const filteredUrls = urls.filter((url): url is string => url !== null);
      setUploadedUrls((prevUrls) =>
        prevUrls ? [...prevUrls, ...filteredUrls] : [...filteredUrls]
      );
    } catch (error) {
      setSnackbarMessage("An error occurred during upload. Please try again.");
      setSeverity(true);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setIsFileUpload(false);
    }
  };

  const handleFileRemove = async (index: number, uuid: string) => {
    try {
      if (!authHeader || (!gogigRequestId && !gogigReqId)) return;
      setDeletingIndex(uuid);
      deletingIndexRef.current = uuid;

      if (uploadedUrls && uploadedUrls[index]) {
        await deleteFileFromS3(authHeader, uploadedUrls[index] as string);
        await removeClientUrl(
          authHeader,
          gogigReqId ?? gogigRequestId,
          uploadedUrls[index] as string
        );
        const updatedFiles = selectedFiles.filter((file) => file.uuid !== uuid);
        setSelectedFiles(updatedFiles);
        const updatedUploadedUrls = uploadedUrls?.filter((_, i) => i !== index); // Remove URL at the same index
        setUploadedUrls(updatedUploadedUrls);
        // Reset the file input
        const fileInput = document.getElementById(
          "file-input"
        ) as HTMLInputElement;
        if (fileInput) {
          fileInput.value = "";
        }

        setSnackbarMessage("File Deleted Successfully");
        setSnackbarOpen(true);
        setDeletingIndex(null);
        setSeverity(false);
      }

      // Update the local states
    } catch (error) {
      setSnackbarMessage("File Deletion failed");
      setSnackbarOpen(true);
      setDeletingIndex(null);
      setSeverity(true);
      console.error("Error deleting file:", error);
    }
  };

  const simulateUpload = (index: string) => {
    setUploadProgress((prev) => ({ ...prev, [index]: 0 }));

    const interval = setInterval(() => {
      setUploadProgress((prev) => {
        const progress = prev[index] + 10;

        if (progress >= 100) {
          clearInterval(interval);
          return { ...prev, [index]: 100 };
        }
        if (isFileUpload === true) {
          clearInterval(interval);
          return { ...prev, [index]: 100 };
        }

        return { ...prev, [index]: progress };
      });
    }, 3000);
  };

  // Define an interface for the expected response structure

  const handleUpload = async () => {
    if (!authHeader) return;
    const hasRequiredFile = selectedFiles.some((selectedFile) =>
      [
        "application/pdf", // PDF
        "application/vnd.ms-powerpoint", // PPT
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/zip",
        "application/x-zip-compressed",
      ].includes(selectedFile.file.type)
    );

    if (!hasRequiredFile) {
      setSnackbarMessage(
        "You must upload at least one file of type PDF, PPT, or ZIP."
      );

      setSnackbarOpen(true);
      setSeverity(true); // true can represent error severity
      setSeverity(true);
      return;
    }
    // Ensure the URLs are valid before proceeding to create the audit request
    if (uploadedUrls && uploadedUrls.every((url) => url !== null)) {
      await handleCreateAuditRequest({
        clientUrls: uploadedUrls,
        comment: comment,
        action: requestDetails?.allowRequestEdit
          ? "updateRequest"
          : "createRequest",
      });
    } else {
      setSnackbarMessage("Some files failed to upload.");
      setSeverity(true); // true represents error severity
      setSnackbarOpen(true);
    }
  };

  const handleSaveCampaignDetails = async ({
    campaignName,
    location,
    campaignStartDate,
    campaignEndDate,
    campaignType,
  }: AuditRequestPayload) => {
    setLoading(true);
    let payload: FileUrls = {
      campaignName: campaignName,
      location: location,
      campaignStartDate: campaignStartDate,
      campaignEndDate: campaignEndDate,
      vehicleClass: campaignType,
    };
    try {
      if (requestDetails?.allowRequestEdit) {
        if (!authHeader || !gogigReqId) return;
        await updateUserAuditRequest(
          authHeader,
          gogigReqId,
          payload,
          requestDetails.allowRequestEdit
        );

        window.history.pushState(
          null,
          "",
          `/upload-createRequest/2/${campaignType}/${gogigReqId}`
        );
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage(
          "Campaign Details Updated Successfully, Now Please upload the files to update your request"
        );
        setSeverity(false);
      } else {
        if (authHeader) {
          const response = await createUserAuditRequest(authHeader, payload);
          const data = (response.data as {
            auditRequestRecordData: {
              gogigRequestId: string;
              requesterId: string;
            };
          }).auditRequestRecordData;
          setGogigRequestId(data.gogigRequestId);
          window.history.pushState(
            null,
            "",
            `/upload-createRequest/2/${campaignType}/${data.gogigRequestId}`
          );
          setLoading(false);
          setSnackbarOpen(true);
          setSnackbarMessage(
            "Campaign Details Saved Successfully, Now Please upload the files to create a request"
          );
          setSeverity(false);
        }
      }
    } catch (error) {
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Saving Campaign Details Failed");
      setSeverity(true);
    }
  };
  const handleCreateAuditRequest = async ({
    clientUrls,
    comment,
    action,
  }: AuditRequestPayload) => {
    if (!clientUrls || clientUrls.length === 0) {
      return;
    }
    setLoading(true);
    let payload: FileUrls = {
      clientUrls: clientUrls.join(", "),
      clientComment: comment,
      action: action,
    };

    try {
      if (requestDetails?.allowRequestEdit) {
        if (!authHeader || !gogigReqId) return;
        setIsFileUpload(true);
        // First update request
        await updateUserAuditRequest(authHeader, gogigReqId, payload);
        setIsUpdated(true);
        // Show success message
        setLoading(false);
        setIsFileUpload(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Audit Request Updated Successfully");
        setSeverity(false);

        // Show navigation message
        setTimeout(() => {
          setSnackbarMessage("Navigating to your request details page...");
          // Navigate after showing the message for 2 seconds
          setTimeout(() => {
            navigate(`/audit-process/${gogigReqId}`);
          }, 2000);
        }, 1500);
      } else {
        if (authHeader && (gogigReqId || gogigRequestId)) {
          setIsFileUpload(true);
          await updateUserAuditRequest(
            authHeader,
            gogigReqId ?? gogigRequestId,
            payload
          );
          setIsUpdated(true);
          setIsFileUpload(false);
          setLoading(false);
          setSnackbarOpen(true);
          setSnackbarMessage("Audit Request Created Successfully");
          setSeverity(false);

          setTimeout(() => {
            setSnackbarMessage("Navigating to your request details page...");
            setTimeout(() => {
              navigate(`/audit-process/${gogigReqId ?? gogigRequestId}`);
            }, 2000);
          }, 1500);
        }
      }
    } catch (error) {
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Audit Request Creation Failed");
      setSeverity(true);
      setIsFileUpload(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center align horizontally
        justifyContent: "center", // Center align vertically
        overflow: "auto",
        width: "100%",
      }}
    >
      {/* Global Snackbar */}
      <GlobalSnackbar
        open={error != null || snackbarOpen}
        message={error ? error : snackbarMessage ? snackbarMessage : ""}
        onClose={() => setSnackbarOpen(!snackbarOpen)}
        severity={error || severity ? "error" : "success"}
        vertical="top"
        horizontal="center"
      />
      <AuditRequestForm
        requestDetails={requestDetails}
        location={location}
        isUpdated={isUpdated}
        campaignEndDate={campaignEndDate}
        campaignStartDate={campaignStartDate}
        campaignName={campaignName}
        campaignType={campaignType}
        errorMessage={errorMessage}
        handleInputChange={handleInputChange}
        handleUpload={handleUpload}
        deletingIndex={deletingIndex}
        onRemoveFile={handleFileRemove}
        loading={loading}
        setComment={setComment}
        comment={comment}
        uploadProgress={uploadProgress}
        selectedFiles={selectedFiles}
        handleFileSelection={handleFileSelection}
        isFileUpload={isFileUpload}
        handleNextStep={handleNextStep}
        step={step}
      />
    </Box>
  );
};

export default UploadAndCreateRequest;
