import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Card,
  Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthProvider";
import { getAuditRequestById } from "../../clients/getAuditRequestById";
import { AuditRequest } from "./vehicleAuditComponents/types";
import getSignedUrl from "../../clients/getSignedUrl";
import RequestStatusProgress from "./RequestStatusProgress";
import AuditRequestDetailsCard from "./AuditRequestDetailsCard";
import getFileData from "../../clients/getFileData";
import { FileDataPayload } from "./UploadAndCreateRequest";
export interface ProcessTimestamps {
  campaignStartTime?: Date;
  campaignEndTime?: Date;
  requestCreatedAt?: Date;
  reviewStartedAt?: Date;
  reviewCompletedAt?: Date;
  processingStartedAt?: Date;
  processingCompletedAt?: Date;
  resultReviewStartedAt?: Date;
  resultReviewCompletedAt?: Date;
}
const AuditRequestPage = () => {
  const [fileData, setFileData] = useState<AuditRequest>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileDetails, setFileDetails] = useState<
    { name: string; url: string }[]
  >([]);
  const { gogigReqId } = useParams<{ gogigReqId: string }>();
  const { authHeader } = useAuthContext();

  const handlePptUrl = async (pptUrl: string): Promise<string | undefined> => {
    if (!authHeader || !gogigReqId) return;

    try {
      const res = await getSignedUrl(authHeader, gogigReqId, pptUrl);
      const signedUrl = res.data as { url: string };
      return signedUrl.url;
    } catch (error) {
      console.error("Failed to fetch signed PPT URL", error);
    }
  };
  const timeStamps = {
    campaignStartTime: fileData?.campaignStartTime
      ? new Date(fileData.campaignStartTime)
      : undefined,
    campaignEndTime: fileData?.campaignEndTime
      ? new Date(fileData.campaignEndTime)
      : undefined,
    requestCreatedAt: fileData?.requestCreatedAt
      ? new Date(fileData.requestCreatedAt)
      : undefined,
    reviewStartedAt: fileData?.reviewStartedAt
      ? new Date(fileData.reviewStartedAt)
      : undefined,
    reviewCompletedAt: fileData?.reviewCompletedAt
      ? new Date(fileData.reviewCompletedAt)
      : undefined,
    processingStartedAt: fileData?.processingStartedAt
      ? new Date(fileData.processingStartedAt)
      : undefined,
    processingCompletedAt: fileData?.processingCompletedAt
      ? new Date(fileData.processingCompletedAt)
      : undefined,
    resultReviewStartedAt: fileData?.resultReviewStartedAt
      ? new Date(fileData.resultReviewStartedAt)
      : undefined,
    resultReviewCompletedAt: fileData?.resultReviewCompletedAt
      ? new Date(fileData.resultReviewCompletedAt)
      : undefined,
  };
  

  const fetchFileDetails = async (urls: string) => {
    const urlArray = urls.split(",").map((url) => url.trim());
  
    const fileData = await Promise.all(
      urlArray.map(async (url) => {
        try {
          const fileName = url.split("/").pop() || "Unknown File";
      
          // Check if the URL is for a large file
          if (url.includes("gfs-spotrue-staging-files.s3")) {
            const signedUrl = await handlePptUrl(url);
            if(authHeader){
              const res = await getFileData(authHeader, fileName);
              const data = (res.data as { fileData: FileDataPayload }).fileData;
              return signedUrl ? { name: data.originalFileName, url: signedUrl } : null;
            }
          } else {
            if(authHeader){
              const res = await getFileData(authHeader, fileName);
              const data = (res.data as { fileData: FileDataPayload }).fileData;
              return { name: data.originalFileName, url };   
            }
          }
        } catch (error) {
          console.error(`Error processing file for URL: ${url}`, error);
          return null; // Handle error by returning null or any fallback value
        }
      })
    );
    
    setFileDetails(fileData.filter(Boolean) as { name: string; url: string }[]);
  };
  useEffect(() => {
    (async () => {
      try {
        if (authHeader && gogigReqId) {
          setIsLoading(true);
          const response = await getAuditRequestById(authHeader, gogigReqId);
          const data = response as AuditRequest;
          setFileData(data);

          if (data.clientUrls) {
            await fetchFileDetails(data.clientUrls);
          }
        }
      } catch (error) {
        console.error("Error fetching audit request:", error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authHeader]);

  return (
    <Container sx={{padding:2, mt:1}}   >
      {isLoading ? (
        <Card
          variant="outlined"
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            boxShadow: 3,
            width: "100%", // Full width for loading
          }}
        >
          <CircularProgress />
        </Card>
      ) : (
        <Box display="flex" flexDirection="column" gap={4} width="100%">
             {/* Audit Request Details Card */}
             <AuditRequestDetailsCard fileData={fileData} fileDetails={fileDetails}/>
          <Card
            variant="outlined"
            sx={{
              borderRadius: "8px",
              boxShadow: 4,
              backgroundColor: "#f9f9f9",
            }}
          >
            {fileData && (
              <Box display={"flex"} sx={{flexDirection:{md:"row",xs:"column"}}}>
                {/* Request Progress */}
                <RequestStatusProgress
                  requestStatus={fileData.requestStatus}
                  dueDate={fileData.dueDate}
                  allowRequestEdit={fileData.allowRequestEdit}
                  timeStamps={timeStamps}
                  gogigRequestId={fileData.gogigRequestId}
                  clientUrl={fileData.clientUrls}
                  campaignType={encodeURIComponent(fileData.campaignVehicleClass)}
                />
                
                
              </Box>
            )}
          </Card>       
        </Box>
      )}
    </Container>
  );
};
export default AuditRequestPage;
