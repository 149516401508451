import { fetchWrapper } from "@gogig-in/gogig-clients";
import {  SpotrueApiBaseUrl } from "./baseUrls";
export default function   removeClientUrl(authHeader:string,gogigReqId:string,fileUrl:string){
 const url: string = `${SpotrueApiBaseUrl}/removeClientUrl?gogigReqId=${gogigReqId}`;
 return fetchWrapper(url,{
    method: "POST",
    body:{url:fileUrl},
    headers: {
      Authorization: authHeader,
    },})
}