import React from "react";
import {
  Box,
  Avatar,
  Tooltip,
  useTheme,
  Paper,
  Typography,
  useMediaQuery,
  Card,
  Grid
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const campaigns = [
  {
    name: "Auto Branding Audit",
    description: "Validate the authenticity of auto branding with detailed proof analysis.",
    icon: "https://img.icons8.com/color/48/auto-rickshaw.png",
    path: "/upload-createRequest/auto-rickshaw-campaign",
    class: "auto-rickshaw-campaign"
  },
  {
    name: "Bus Branding Audit",
    description:"Detect tampered or fake proofs in bus branding campaigns with accuracy.",
    icon: "https://img.icons8.com/color/48/bus.png",
    path: "/upload-createRequest/bus-campaign",
    class: "bus-campaign"
  },
  {
    name: "Car Branding Audit",
    description: "Ensure car branding evidence is genuine and free from manipulation.",
    icon: "https://img.icons8.com/color/48/taxi.png",
    path: "/upload-createRequest/cab-campaign",
    class: "cab-campaign" 
  }
];

const CampaignNavigation = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor: "transparent",
        width: "100%",
        mb:3,
        mt:2
      }}
    >
      <Grid 
        container 
        spacing={2}
        justifyContent={{ 
          xs: "center", // Center on mobile
          sm: "center", // Center on tablet
          md: "space-between" // Space between on desktop
        }}
      >
        {campaigns.map((campaign, index) => (
          <Grid 
            item 
            xs={12} // Full width on mobile (one per row)
            sm={6}  // Half width on tablet (two per row)
            md={4}  // One third width on desktop (all in one row)
            key={index}
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                sm: index === 2 ? 'center' : 'flex-start' // Center the last item on tablet
              }
            }}
          >
            <Card
              variant="outlined"
              onClick={() => navigate(campaign.path)}
              elevation={2}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                p: 1,
                backgroundColor: "white",
                cursor: "pointer",
                transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
                borderRadius: 2,
                width: {
                  xs: "100%", // Full width on mobile
                  sm: "350px", // Fixed width on tablet/desktop
                },
                boxShadow: theme.shadows[1],
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.04)",
                  "& .MuiAvatar-root": {
                    transform: "scale(1.1)",
                    borderColor: theme.palette.primary.main,
                    boxShadow: `0 0 0 2px ${theme.palette.background.paper}, 0 0 0 4px ${theme.palette.primary.main}`
                  }
                }
              }}
            >
              <Tooltip
                title={isMobile ? campaign.description : ""}
                arrow
                placement="top"
                enterDelay={500}
              >
                <Avatar
                  src={campaign.icon}
                  alt={campaign.name}
                  className="MuiAvatar-root"
                  sx={{
                    width: { xs: 70, sm: 75, md: 70 },
                    height: { xs: 70, sm: 75, md: 70 },
                    transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
                    border: 2,
                    borderColor: theme.palette.divider,
                    bgcolor: theme.palette.background.paper
                  }}
                />
              </Tooltip>

              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.text.primary,
                    fontSize: { xs: "0.875rem", sm: "1rem" }
                  }}
                >
                  {campaign.name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.text.secondary,
                    display: "block",
                    fontSize: { xs: "0.75rem", sm: "0.875rem" }
                  }}
                >
                  {campaign.description}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default CampaignNavigation;