import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SpotrueFooterNavigation from "./SpotrueFooterNavigation";
import SpotrueFooterSocialLinks from "./spotrue-footer-social-links";



const SpotrueFooter = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#18191C",
        paddingTop: "60px",
        color: "primary.contrastText",
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          {" "}
          {/* Added alignItems="center" */}
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                width: { xs: "100%", md: 360 },
                display: "flex",
                flexDirection: "column",
                alignItems:  "flex-start" , // Center on mobile, left-aligned on desktop
              }}
            >
              <Box mb={1}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/logos/spotrue.png`}
                  alt=" SpoTrue "
                  style={{
                    width: 150,
                    display: "block", // Ensures proper image alignment
                  }}
                />
              </Box>
              <Typography
                variant="subtitle1"
                sx={{
                  letterSpacing: 1,
                  mb: 2,
                  textAlign: { xs: "center", md: "left" }, // Center on mobile, left-aligned on desktop
                }}
              >
                Upload. Process. Verify.
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  letterSpacing: 1,
                  mb: 2,
                  textAlign: { xs: "center", md: "left" }, // Center on mobile, left-aligned on desktop
                }}
              >
                Upload the data and verify its authenticity
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <SpotrueFooterNavigation />
          </Grid>
        </Grid>
        <hr style={{ width: "100%", borderTop: "1px #ccc" }} />

        <SpotrueFooterSocialLinks />
      </Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>
          © Copyright 2025・ SpoTrue ・All rights reserved.
        </Typography>
      </Box>
      <br />
    </Box>
  );
};

export default SpotrueFooter;
