import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
  IconButton,
  LinearProgress,
  ListItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuditRequest } from "../types";
import { SelectedFile } from "../../UploadAndCreateRequest";

interface FileUploadProps {
  requestDetails?: AuditRequest;
  selectedFiles: SelectedFile[];
  uploadProgress: Record<string, number>;
  deletingIndex: string | null;
  comment: string;
  loading: boolean;
  isFileUpload: boolean | null;
  isUpdated: boolean;
  onFileSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: (index: number, uuid: string) => void;
  onCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUpload: () => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({
  requestDetails,
  selectedFiles,
  uploadProgress,
  deletingIndex,
  comment,
  loading,
  isFileUpload,
  isUpdated,
  onFileSelection,
  onRemoveFile,
  onCommentChange,
  onUpload,
}) => {
  const [showProgress, setShowProgress] = useState<Record<string, boolean>>({});

  useEffect(() => {
    // Iterate through uploadProgress to set visibility using UUIDs
    Object.entries(uploadProgress).forEach(([uuid, progress]) => {
      if (progress === 100) {
        setTimeout(() => {
          setShowProgress((prev) => ({ ...prev, [uuid]: false }));
        }, 1000); // Delay to show 100% briefly
      } else {
        setShowProgress((prev) => ({ ...prev, [uuid]: true }));
      }
    });
  }, [uploadProgress]);
  return (
    <>
      <Typography
        variant="body1"
        sx={{
          mb: 2,
          fontWeight: 500,
        }}
      >
        {requestDetails?.allowRequestEdit ? "Reupload" : "Upload"} your files
        for audit. Supported formats: PDF, PPTX, ZIP, XLSX. You can upload up to{" "}
        <strong>4 files.</strong>
      </Typography>

      {/* File Upload Section */}
      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          padding: 3,
          border: "2px dashed #1976d2",
          textAlign: "center",
          borderRadius: 4,
          cursor: "pointer",
        }}
        onClick={() => document.getElementById("file-input")?.click()}
      >
        <CloudUploadIcon sx={{ fontSize: 48, color: "primary.main" }} />
        <Typography variant="body1" sx={{ marginTop: 1 }}>
          Click here to upload files
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "gray", fontSize: "0.85rem", marginTop: 1 }}
        >
          Drag and drop is also supported
        </Typography>
        <input
          type="file"
          hidden
          id="file-input"
          multiple
          onChange={onFileSelection}
        />
      </Box>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mt: 2, fontStyle: "italic", textAlign: "center" }}
      >
        Providing more detailed and accurate data will result in better and more
        tailored audit reports.
      </Typography>

      {/* File List */}
      {selectedFiles.map((selectedFile, index) => (
        <ListItem
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor:
              deletingIndex === selectedFile.uuid ? "#fff3e0" : "#eef2ff", // Highlight background during deletion
            borderRadius: 2,
            marginY: 1,
            paddingX: 2,
            gap: 2,
            position: "relative", // For overlay
            opacity: deletingIndex === selectedFile.uuid ? 0.8 : 1,
            transition: "all 0.3s ease",
            flexWrap: "wrap", // Allow wrapping
          }}
        >
          {/* Deletion Overlay */}
          {deletingIndex === selectedFile.uuid && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                zIndex: 1,
                borderRadius: 2,
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <CircularProgress size={24} />
                <Typography
                  variant="caption"
                  display="block"
                  sx={{ mt: 1, color: "primary.main" }}
                >
                  Deleting file...
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              minWidth: 0, // Prevent horizontal overflow
            }}
          >
            <Typography
              variant="body2"
              sx={{
                wordWrap: "break-word",
                overflowWrap: "break-word", // Break long words
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis", // Adds ellipsis for excessively long text
                maxWidth: "100%", // Ensure it doesn't overflow the container
                marginBottom: 1,
              }}
            >
              {selectedFile.file.name}
              {uploadProgress[selectedFile.uuid] !== undefined &&
                uploadProgress[selectedFile.uuid] < 100 && (
                  <Typography
                    component="span"
                    variant="caption"
                    color="text.secondary"
                    sx={{ ml: 1 }}
                  >
                    - {(selectedFile.file.size / 1000000).toFixed(2)} MB
                  </Typography>
                )}
            </Typography>

            {/* Progress bar section */}
            {uploadProgress[selectedFile.uuid] !== undefined &&
            showProgress[selectedFile.uuid] !== false ? (
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress[selectedFile.uuid]}
                  color={
                    uploadProgress[selectedFile.uuid] === 100
                      ? "success"
                      : "primary"
                  }
                  sx={{ height: 6, borderRadius: 3, marginBottom: 0.5 }}
                />
                <Typography
                  variant="caption"
                  color={
                    uploadProgress[selectedFile.uuid] === 100
                      ? "success.main"
                      : "text.secondary"
                  }
                >
                  {uploadProgress[selectedFile.uuid] === 100
                    ? "Upload Complete"
                    : `${uploadProgress[selectedFile.uuid]}% completed`}
                </Typography>
              </Box>
            ) : (
              <Typography variant="caption" color="success.main">
                Upload Complete
              </Typography>
            )}
          </Box>

          <Tooltip
            title={
              deletingIndex === selectedFile.uuid
                ? "Deleting..."
                : "Remove File"
            }
          >
            <span>
              <IconButton
                edge="end"
                onClick={() => onRemoveFile(index, selectedFile.uuid)}
                disabled={!!isFileUpload || deletingIndex !== null}
                sx={{
                  marginLeft: 1,
                  alignSelf: "start",
                }}
              >
                {deletingIndex === selectedFile.uuid ? (
                  <CircularProgress size={20} color="primary" />
                ) : (
                  <DeleteIcon
                    color={
                      isFileUpload || deletingIndex !== null
                        ? "disabled"
                        : "error"
                    }
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </ListItem>
      ))}
      {selectedFiles.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" sx={{ mb: 2, fontWeight: 500 }}>
            <strong>Step 3:</strong> Add comments or instructions for the audit
            team.
          </Typography>
          <TextField
            label="Comments about the files"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            value={comment}
            onChange={onCommentChange}
            placeholder="E.g., password for protected files, specific instructions"
            disabled={isFileUpload === true}
          />
        </Box>
      )}

      {/* Upload Button */}
      <Box sx={{ textAlign: "center", marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onUpload}
          disabled={isFileUpload || isUpdated || loading}
        >
          {isFileUpload
            ? "Request Submitting..."
            : isUpdated
            ? "Request Submitted"
            : "Submit Request"}
        </Button>
      </Box>
    </>
  );
};

export default FileUploadComponent;
