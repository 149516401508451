import React, { useEffect } from "react";
import { Typography, Container, Box } from "@mui/material";
import { AuditRequest } from "../types";
import CampaignDetailsComponent from "./CampaignDetailsComponent";
import FileUploadComponent from "./FileUploadComponent";
import { campaigns } from "../../../dashboard/CampaignNavigation";
import { SelectedFile } from "../../UploadAndCreateRequest";

interface AuditRequestFormProps {
  loading: boolean;
  isUpdated: boolean;
  selectedFiles: SelectedFile[];
  isFileUpload: boolean | null;
  uploadProgress: Record<string, number>;
  setComment: React.Dispatch<React.SetStateAction<string>>;
  comment: string;
  deletingIndex: string | null;
  requestDetails?: AuditRequest;
  errorMessage: string | null;
  location: string;
  campaignEndDate: string;
  campaignStartDate: string;
  campaignName: string;
  campaignType?: string | undefined;
  handleUpload: () => void;
  onRemoveFile: (index: number, uuid:string) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNextStep: () => Promise<void>;
  step: number;
}

export default function AuditRequestForm({
  loading,
  isFileUpload,
  isUpdated,
  uploadProgress,
  selectedFiles,
  comment,
  requestDetails,
  errorMessage,
  location,
  campaignEndDate,
  campaignStartDate,
  campaignName,
  campaignType,
  setComment,
  handleUpload,
  onRemoveFile,
  deletingIndex,
  handleInputChange,
  handleFileSelection,
  handleNextStep,
  step,
}: AuditRequestFormProps) {
  const [uploadCompleted, setUploadCompleted] = React.useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    Object.entries(uploadProgress).forEach(([key, value]) => {
      const index = Number(key);
      if (value === 100 && !uploadCompleted[index]) {
        setTimeout(() => {
          setUploadCompleted((prev) => ({ ...prev, [index]: true }));
        }, 1000);
      }
    });
  }, [uploadProgress, uploadCompleted]);

  const fieldInfo = {
    campaignName: "Provide a name for your campaign.",
    location: "Specify the location or region where the campaign took place.",
    campaignStartDate: "Choose the starting date of your campaign.",
    campaignEndDate: "Choose the ending date of your campaign.",
  };
  const filteredCampaign = campaigns.find(
    (campaign) => campaign.class === campaignType
  );
  return (
    <Container
      sx={{
        maxWidth: "100%",
        margin: "0 auto",
        padding: { xs: 0, sm: 3, md: 4 },
        backgroundColor: "#fff",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
      }}
    >
      {filteredCampaign && (
        <Box sx={{ textAlign: "center", marginBottom: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2 }}>
            {filteredCampaign.name}
          </Typography>
          <img
            src={filteredCampaign.icon}
            alt={filteredCampaign.name}
            width={48}
            height={48}
          />
        </Box>
      )}
      {/* Title */}
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          fontWeight: 600,
          marginBottom: 3,
        }}
      >
        {step === 1 ? "Step 1: Campaign Details" : "Step 2: Upload Files"}
      </Typography>

      {/* Instructions */}
      {step === 1 && (
        <CampaignDetailsComponent
          requestDetails={requestDetails}
          campaignName={campaignName}
          location={location}
          campaignStartDate={campaignStartDate}
          campaignEndDate={campaignEndDate}
          fieldInfo={fieldInfo}
          errorMessage={errorMessage}
          handleInputChange={handleInputChange}
          handleNextStep={handleNextStep}
          loading={loading}
        />
      )}

      {/* Step 2: File Upload */}
      {step === 2 && (
        <FileUploadComponent
          requestDetails={requestDetails}
          selectedFiles={selectedFiles}
          uploadProgress={uploadProgress}
          deletingIndex={deletingIndex}
          comment={comment}
          loading={loading}
          isFileUpload={isFileUpload}
          isUpdated={isUpdated}
          onFileSelection={handleFileSelection}
          onRemoveFile={onRemoveFile}
          onCommentChange={(e) => setComment(e.target.value)}
          onUpload={handleUpload}
        />
      )}
    </Container>
  );
}
