import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export interface FileUrls {
  clientUrls?: string;
  clientComment?: string;
  campaignName?: string;
  location?: string;
  campaignStartDate?: string;
  campaignEndDate?: string;
  vehicleClass?: string;
  action?:string;
}

export async function updateUserAuditRequest(
  authHeader: string,
  gogigReqId: string,
  data: FileUrls,
  allowEditRequest?:boolean,
) {
  const payload = {
    clientUrls: data.clientUrls,
    clientComment: data.clientComment,
    action:data.action
  };
  const payload2={
    campaignName: data.campaignName,
  campaignLocation: data.location,
  campaignStartTime: data.campaignStartDate,
  campaignEndTime:data.campaignEndDate,
  vehicleClass:data.vehicleClass,
  }

  const url: string = data.action?.trim()
    ? `${SpotrueApiBaseUrl}/saveUserAuditRequest?gogigReqId=${gogigReqId}&action=${data.action}`
    : `${SpotrueApiBaseUrl}/saveUserAuditRequest?gogigReqId=${gogigReqId}`; // Update endpoint based on cURL
  return fetchWrapper(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
    body: allowEditRequest?payload2:payload, // Ensure the payload is properly serialized as JSON
  });
}
