import {
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import LockIcon from "@mui/icons-material/Lock";


import { useParams } from "react-router-dom";
import initialPasswordValues, { PasswordValues } from "./PasswordValues";
import SuccessSubmission from "../successSubmission";
import { useAuthContext } from "../../providers/AuthProvider";
import { newPassword } from "../../clients/newPassword";


const NewPasswordPage = () => {
  const { token } = useParams<{token:string}>();
  const {authHeader} = useAuthContext();
  const [values, setValues] = useState<PasswordValues>(initialPasswordValues);
  const [errors, setErrors] = useState<any>({});
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isConfirmNewPasswordValid, setIsConfirmNewPasswordValid] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "newPassword") {
      const isPasswordValid1 = validatePassword(value);
      setIsNewPasswordValid(isPasswordValid1);
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        newPassword: isPasswordValid1
          ? ""
          : "Password must contain at least one uppercase letter, one digit, and be a minimum of 8 characters long.",
      }));
    } else if (name === "confirmNewPassword") {
      const isNewPassword = values.newPassword;
      const isPasswordValid2 = isNewPassword === value;
      setIsConfirmNewPasswordValid(isPasswordValid2);
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        confirmNewPassword: isPasswordValid2 ? "" : "Passwords do not match.",
      }));
    }

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setSuccess(false);
  };

  const togglePasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async () => {
    let newErrors = { ...errors };

    if (!values.newPassword) {
      newErrors = {
        ...newErrors,
        newPassword: "New password is required",
      };
    }
    if (!values.confirmNewPassword) {
      newErrors = {
        ...newErrors,
        confirmNewPassword: "Confirm New password is required",
      };
    }
    if (values.newPassword !== values.confirmNewPassword) {
      newErrors = {
        ...newErrors,
        confirmNewPassword: "Passwords do not match",
      };
    }

    setErrors(newErrors);
    if (!newErrors.newPassword && !newErrors.confirmNewPassword && token) {
      if (isNewPasswordValid && isConfirmNewPasswordValid) {
        setLoading(true);
        if(!authHeader)return;
        try {
          await newPassword(authHeader,values.newPassword, token);
          setSuccess(true);
          setLoading(false);
        } catch (error) {
          console.error("Error During newPassword:", error);
          setLoading(false);
        }
      }
    }
  };
  return (
    <>
      {success ? (
        <SuccessSubmission
          logoName={"access-granted"}
          text={"You have successfully updated password."}
        />
      ) : (
        <Container style={{ textAlign: "center" }}>
          <Box
            sx={{
              maxWidth: "400px",
              border: "2px solid #eee ",
              borderRadius: "10px",
              padding: "25px",
              margin: "40px auto 0 auto",
              textAlign: "left",
            }}
          >
            <Typography
              variant="h3"
              sx={{ display: "flex", justifyContent: "center" }}
              gutterBottom
            >
              Update Your Password
            </Typography>
            <TextField
              type="password"
              size="small"
              fullWidth
              name="newPassword"
              autoComplete="new-password"
              value={values.newPassword}
              onChange={handleChange}
              placeholder="New Password"
              sx={{
                marginTop: "15px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                className: "textField-input",
              }}
              error={!!errors.newPassword}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "red",
                marginLeft: "12px",
              }}
            >
              {errors.newPassword && (
                <>
                  <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                  <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                    {errors.newPassword}
                  </span>
                </>
              )}
            </div>
            <TextField
              type={showConfirmPassword ? "text" : "password"}
              size="small"
              fullWidth
              name="confirmNewPassword"
              autoComplete="new-password"
              value={values.confirmNewPassword}
              onChange={handleChange}
              placeholder="Confirm New Password"
              sx={{
                marginTop: "15px",
              }}
              error={!!errors.confirmNewPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                className: "textField-input",
              }}
            />
            {errors.confirmNewPassword && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "red",
                  marginLeft: "12px",
                }}
              >
                <ErrorIcon sx={{ fontSize: "16px", color: "red" }} />
                <span style={{ marginLeft: "12px", fontSize: "14px" }}>
                  {errors.confirmNewPassword}
                </span>
              </div>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                sx={{ fontSize: "12px", size: "14px", minWidth: "150px" }}
                variant="contained"
                onClick={handleSubmit}
                fullWidth
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} size={24} />
                ) : (
                  "Update password"
                )}
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};
export default NewPasswordPage;
