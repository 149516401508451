import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../providers/AuthProvider";

interface ProtectedRouteProps {
  redirectTo: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectTo }) => {
  const { isAuthenticated } = useAuthContext();

  // If the user is authenticated, redirect them to the specified path (VehicleAudit)
  if (!isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  // If not authenticated, render the requested route
  return <Outlet />;
};

export default ProtectedRoute;
