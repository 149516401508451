import React from "react";
import { Box, keyframes } from "@mui/material";
import { styled } from "@mui/material/styles";

// Custom Styled Components
const LogoSlider = styled(Box)(() => ({
  background: "white",
  height: "100px",
  overflow: "hidden",
  position: "relative",
  "&::before, &::after": {
    content: '""',
    height: "100%",
    width: "150px",
    position: "absolute",
    zIndex: 2,
    top: 0,
  },
  "&::before": {
    left: 0,
    background: "linear-gradient(to right, white 0%, rgba(255,255,255,0) 100%)",
  },
  "&::after": {
    right: 0,
    background: "linear-gradient(to left, white 0%, rgba(255,255,255,0) 100%)",
  },
}));

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const LogoSlide = styled(Box)(() => ({
  animation: `${scroll} 30s linear infinite`,
  display: "flex",
  "&:hover": {
    animationPlayState: "paused",
  },
}));

const LogoItem = styled(Box)(() => ({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px",
  flexShrink: 0,
}));

const LogoCarousel: React.FC = () => {
  const logos = [
    {
      id: 1,
      name: "NoBroker",
      src: `${process.env.PUBLIC_URL}/images/logos/nb_logo.png`,
    },
    {
      id: 2,
      name: "BeepKart",
      src: `${process.env.PUBLIC_URL}/images/logos/beepkart-logo.png`,
    },
    {
      id: 3,
      name: "Kisaan",
      src: `${process.env.PUBLIC_URL}/images/logos/kissan-logo.png`,
    },
    {
      id: 4,
      name: "Hello Kids",
      src: `${process.env.PUBLIC_URL}/images/logos/hello-kids-logo.jpeg`,
    },
    {
      id: 5,
      name: "Tirumala Steel",
      src: `${process.env.PUBLIC_URL}/images/logos/tirumala-steel-logo.png`,
    },
    {
      id: 6,
      name: "Surya",
      src: `${process.env.PUBLIC_URL}/images/logos/surya-logo.png`,
    },
    {
      id: 7,
      name: "Porter",
      src: `${process.env.PUBLIC_URL}/images/logos/porter-logo.png`,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 3,
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <LogoSlider>
        <LogoSlide>
          {/* Duplicate the logos array to create seamless infinite scroll */}
          {[...logos, ...logos].map((logo, index) => (
            <LogoItem key={`${logo.id}-${index}`}>
              <Box
                component="img"
                src={logo.src}
                alt={logo.name}
                sx={{
                  maxHeight: { xs: "40px", sm: "50px", md: "60px" },
                  maxWidth: "100%",
                  objectFit: "contain",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              />
            </LogoItem>
          ))}
        </LogoSlide>
      </LogoSlider>
    </Box>
  );
};

export default LogoCarousel;